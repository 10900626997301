import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const PeopleAboutPattern = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="1722"
        height="493"
        viewBox="0 0 1722 493"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M315.298 185.546L478.325 185.546C484.76 185.546 489.995 190.726 490.063 197.161C490.54 242.28 454.096 279.112 408.974 279.112H328.799C315.646 279.112 305.022 268.376 305.161 255.223L305.799 194.945C305.854 189.738 310.091 185.546 315.298 185.546Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="475.432"
          y="255.628"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(90 475.432 255.628)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M213.437 228.383L213.437 302.165C213.437 307.412 209.184 311.665 203.937 311.665H131.908C125.26 311.665 119.871 306.276 119.871 299.628V298.28C119.871 253.141 156.463 216.549 201.602 216.549C208.138 216.549 213.437 221.847 213.437 228.383Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M582.937 290.23V246.967C582.937 198.659 622.097 159.498 670.405 159.498C673.773 159.498 676.503 162.228 676.503 165.596V290.072C676.503 295.319 672.249 299.572 667.003 299.572H592.279C587.119 299.572 582.937 295.39 582.937 290.23Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="736.502"
          y="331.572"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          transform="rotate(-180 736.502 331.572)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M343.66 225.891V269.154C343.66 317.462 304.499 356.623 256.191 356.623C252.824 356.623 250.094 353.893 250.094 350.525L250.094 226.049C250.094 220.802 254.347 216.549 259.594 216.549L334.317 216.549C339.477 216.549 343.66 220.731 343.66 225.891Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M179.492 154.538H129.828C81.52 154.538 42.359 193.699 42.359 242.006C42.359 245.374 45.089 248.104 48.4567 248.104H172.933C178.18 248.104 182.433 243.851 182.433 238.604V157.479C182.433 155.855 181.116 154.538 179.492 154.538Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M447.123 385.072H445.681C442.253 385.072 439.475 382.294 439.475 378.866V301.006C439.475 295.759 443.728 291.506 448.975 291.506H528.494C531.861 291.506 534.591 294.236 534.591 297.604C534.591 345.911 495.43 385.072 447.123 385.072Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M57.1228 405.072H55.6807C52.2533 405.072 49.4749 402.294 49.4749 398.866L49.4749 321.006C49.4749 315.759 53.7282 311.506 58.9749 311.506H138.494C141.861 311.506 144.591 314.236 144.591 317.604C144.591 365.911 105.43 405.072 57.1228 405.072Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M498.033 154.538H496.591C493.163 154.538 490.385 157.317 490.385 160.744V238.604C490.385 243.851 494.638 248.104 499.885 248.104H579.404C582.771 248.104 585.501 245.374 585.501 242.007C585.501 193.699 546.341 154.538 498.033 154.538Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M296.955 217.1L222.388 217.1C217.142 217.1 212.888 212.847 212.888 207.6V133.034C212.888 127.787 217.142 123.534 222.388 123.534L296.955 123.534C302.201 123.534 306.455 127.787 306.455 133.034V207.6C306.455 212.847 302.201 217.1 296.955 217.1Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M396.802 176.232V132.969C396.802 84.661 435.963 45.5 484.271 45.5C487.638 45.5 490.368 48.23 490.368 51.5977V176.074C490.368 181.321 486.115 185.574 480.868 185.574L406.145 185.574C400.985 185.574 396.802 181.391 396.802 176.232Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M94.0669 227.6L94.0669 435.342C94.0669 439.813 90.4418 443.439 85.97 443.439C38.7666 443.439 0.500763 405.173 0.500763 357.969L0.500763 227.6C0.500763 222.353 4.75406 218.1 10.0008 218.1L84.5669 218.1C89.8136 218.1 94.0669 222.353 94.0669 227.6Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1415.71 352.026H1252.68C1246.24 352.026 1241.01 346.846 1240.94 340.411C1240.46 295.292 1276.91 258.46 1322.03 258.46H1402.2C1415.36 258.46 1425.98 269.197 1425.84 282.349L1425.2 342.627C1425.15 347.834 1420.91 352.026 1415.71 352.026Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1508.57 309.189V235.407C1508.57 230.16 1512.82 225.907 1518.07 225.907H1590.1C1596.74 225.907 1602.13 231.296 1602.13 237.944V239.292C1602.13 284.431 1565.54 321.024 1520.4 321.024C1513.86 321.024 1508.57 315.725 1508.57 309.189Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1381.34 311.681V268.418C1381.34 220.11 1420.5 180.949 1468.81 180.949C1472.18 180.949 1474.91 183.679 1474.91 187.047V311.524C1474.91 316.77 1470.66 321.024 1465.41 321.024H1390.69C1385.53 321.024 1381.34 316.841 1381.34 311.681Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1542.51 383.034H1592.18C1640.48 383.034 1679.64 343.873 1679.64 295.566C1679.64 292.198 1676.91 289.468 1673.55 289.468H1549.07C1543.82 289.468 1539.57 293.721 1539.57 298.968V380.093C1539.57 381.718 1540.89 383.034 1542.51 383.034Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1712.85 0.5L1714.3 0.5C1717.72 0.5 1720.5 3.27843 1720.5 6.7058V84.5661C1720.5 89.8128 1716.25 94.0661 1711 94.0661L1631.48 94.0661C1628.12 94.0661 1625.39 91.3361 1625.39 87.9685C1625.39 39.661 1664.55 0.5 1712.85 0.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1661.88 132.5H1663.32C1666.75 132.5 1669.53 135.278 1669.53 138.706V216.566C1669.53 221.813 1665.28 226.066 1660.03 226.066H1580.51C1577.14 226.066 1574.41 223.336 1574.41 219.968C1574.41 171.661 1613.57 132.5 1661.88 132.5Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1235.97 383.034H1237.41C1240.84 383.034 1243.62 380.256 1243.62 376.828V298.968C1243.62 293.721 1239.37 289.468 1234.12 289.468H1154.6C1151.23 289.468 1148.5 292.198 1148.5 295.566C1148.5 343.873 1187.66 383.034 1235.97 383.034Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1428.05 320.472H1502.61C1507.86 320.472 1512.11 324.726 1512.11 329.972V404.538C1512.11 409.785 1507.86 414.038 1502.61 414.038H1428.05C1422.8 414.038 1418.55 409.785 1418.55 404.538V329.972C1418.55 324.726 1422.8 320.472 1428.05 320.472Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1334.2 361.341V404.604C1334.2 452.911 1295.04 492.072 1246.73 492.072C1243.37 492.072 1240.64 489.342 1240.64 485.975V361.498C1240.64 356.251 1244.89 351.998 1250.14 351.998H1324.86C1330.02 351.998 1334.2 356.181 1334.2 361.341Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M1624.94 309.972V102.231C1624.94 97.7588 1628.56 94.1337 1633.03 94.1337C1680.24 94.1337 1718.5 132.4 1718.5 179.603V309.972C1718.5 315.219 1714.25 319.472 1709 319.472H1634.44C1629.19 319.472 1624.94 315.219 1624.94 309.972Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

PeopleAboutPattern.defaultProps = {};

export default PeopleAboutPattern;
