import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const PeopleBottomPattern = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="744"
        height="400"
        viewBox="0 0 744 400"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M462.204 249.627C462.149 254.834 457.912 259.026 452.705 259.026H289.678C283.243 259.026 278.008 253.846 277.94 247.411C277.463 202.292 313.907 165.46 359.029 165.46H439.204C452.357 165.46 462.981 176.197 462.842 189.349L462.204 249.627Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="301.57"
          y="188.944"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(-90 301.57 188.944)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M600.4 228.024C593.864 228.024 588.565 222.725 588.565 216.189V142.407C588.565 137.16 592.819 132.907 598.065 132.907H670.095C676.742 132.907 682.132 138.296 682.132 144.944V146.292C682.132 191.431 645.539 228.024 600.4 228.024Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M156.724 145C161.883 145 166.066 149.183 166.066 154.342V197.606C166.066 245.913 126.905 285.074 78.5974 285.074C75.2298 285.074 72.4998 282.344 72.4998 278.976L72.4998 154.5C72.4998 149.253 76.7531 145 81.9998 145L156.724 145Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="0.5"
          y="113"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M450.685 228.024C445.525 228.024 441.343 223.841 441.343 218.681V175.418C441.343 127.11 480.504 87.9495 528.811 87.9495C532.179 87.9495 534.909 90.6795 534.909 94.0471V218.524C534.909 223.77 530.656 228.024 525.409 228.024H450.685Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M619.569 287.093C619.569 288.718 620.886 290.034 622.51 290.034H672.175C720.482 290.034 759.643 250.873 759.643 202.566C759.643 199.198 756.913 196.468 753.546 196.468H629.069C623.823 196.468 619.569 200.721 619.569 205.968V287.093Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M233.411 146.968C233.411 98.661 272.572 59.5 320.88 59.5H322.322C325.749 59.5 328.528 62.2784 328.528 65.7058V143.566C328.528 148.813 324.274 153.066 319.028 153.066H239.509C236.141 153.066 233.411 150.336 233.411 146.968Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M665.411 126.968C665.411 78.661 704.572 39.5 752.88 39.5H754.322C757.749 39.5 760.528 42.2784 760.528 45.7058V123.566C760.528 128.813 756.274 133.066 751.028 133.066H671.509C668.141 133.066 665.411 130.336 665.411 126.968Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M174.501 202.566C174.501 250.873 213.662 290.034 261.969 290.034H263.412C266.839 290.034 269.617 287.256 269.617 283.828V205.968C269.617 200.721 265.364 196.468 260.117 196.468H180.599C177.231 196.468 174.501 199.198 174.501 202.566Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M725.436 226.472C720.189 226.472 715.936 222.219 715.936 216.972V9.23058C715.936 4.75877 719.561 1.13367 724.032 1.13367C771.236 1.13367 809.502 39.3995 809.502 86.6029V216.972C809.502 222.219 805.248 226.472 800.002 226.472H725.436Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

PeopleBottomPattern.defaultProps = {};

export default PeopleBottomPattern;
