import React, { useState } from "react";
import clsx from "clsx";
import { Container, Button, Text } from "@atoms";
import { PersonCard } from "@molecules";
import PeopleAboutPattern from "@svg/PeopleAboutPattern";
import PeopleBottomPattern from "@svg/PeopleBottomPattern";
import PeopleTopPattern from "@svg/PeopleTopPattern";

const PersonGrid = ({ heading, people, buttons, color, specialPattern }) => {
  const [forceOpen, setForceOpen] = useState(false);
  return (
    <div className="my-10 sm:my-20">
      <Container smallerPadding>
        <div
          className={clsx("relative overflow-hidden rounded-3xl py-10", {
            "bg-yellow": color === "yellow",
            "bg-teal": color === "teal",
            "bg-purple": color === "purple",
          })}
        >
          <Text
            variant="h2"
            className={clsx("relative z-10 mb-8 px-4 text-center sm:px-6", {
              "text-purple": color === "yellow",
              "text-white": color !== "yellow",
            })}
          >
            {heading}
          </Text>
          <Container className="relative z-10 flex flex-wrap justify-center gap-4 gap-y-8 px-10 sm:pb-10">
            {people.map(person => {
              return (
                <PersonCard
                  {...person}
                  closeState={[forceOpen, setForceOpen]}
                />
              );
            })}
          </Container>
          {buttons?.length > 0 && (
            <div className="relative z-10 mt-4 flex w-full shrink-0 flex-wrap justify-center gap-4 px-4">
              {buttons?.map(button => {
                return (
                  <Button to={button.url} size="md" color="purple">
                    <Text>{button?.text}</Text>
                  </Button>
                );
              })}
            </div>
          )}
          {specialPattern && (
            <PeopleAboutPattern
              className={clsx(
                "absolute -left-60 bottom-0 z-0 w-[50rem] sm:-bottom-20 sm:-left-80 sm:w-[72rem] md:-bottom-28 md:left-[-22.5%] md:w-[125%]",
                {
                  "text-teal": color === "purple",
                  "text-white": color !== "purple",
                }
              )}
            />
          )}
          {!specialPattern && (
            <>
              <PeopleTopPattern
                className={clsx(
                  "absolute -left-20 -top-16 z-0 w-80 md:-top-24 md:left-0 md:w-[28rem]",
                  {
                    "text-teal": color === "purple",
                    "text-white": color !== "purple",
                  }
                )}
              />
              <PeopleBottomPattern
                className={clsx(
                  "absolute -bottom-16 right-0 z-0 w-96 md:-bottom-40 md:w-[40rem]",
                  {
                    "text-teal": color === "purple",
                    "text-white": color !== "purple",
                  }
                )}
              />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

PersonGrid.defaultProps = {};

export default PersonGrid;
